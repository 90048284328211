import request from '@/utils/request';

//获取所有频道 xigua
export function getAllXiGuaChannels() {
    return request({
        url: '/api/v1/channel/xigua',
        method: 'get'
    });
}

//创建新的频道 xigua {channelName, channelHomeUrl, isValid, note}
export function addNewXiGuaChannel({channelName, channelHomeUrl, isValid, note}) {
    return request({
        url: '/api/v1/channel/xigua',
        method: 'POST',
        data: {channelName, channelHomeUrl, isValid, note}
    });
}

//更新频道 xigua {id, channelName, channelHomeUrl, isValid, note}
export function updateXiGuaChannel({id, channelName, channelHomeUrl, isValid, note}) {
    return request({
        url: '/api/v1/channel/xigua',
        method: 'put',
        data: {id, channelName, channelHomeUrl, isValid, note}
    });
}

//获取所有频道 youtube
export function getAllYouTubeChannels() {
    return request({
        url: '/api/v1/channel/youtube',
        method: 'get'
    });
}

//创建新的频道 youtube  {xiguaChannelId, channelName, channelHomeUrl, description,tags, privacyStatus, categoryId, isValid, note}
export function addNewYouTubeChannel({xiguaChannelId, channelName, channelHomeUrl, description, tags, privacyStatus, categoryId, isValid, note}) {
    return request({
        url: '/api/v1/channel/youtube',
        method: 'POST',
        data: {xiguaChannelId, channelName, channelHomeUrl, description, tags, privacyStatus, categoryId, isValid, note}
    });
}

//更新频道 youtube { id, xiguaChannelId, channelName, channelHomeUrl, description,tags, privacyStatus, categoryId, isValid, note }
export function updateYouTubeChannel({id, xiguaChannelId, channelName, channelHomeUrl, description, tags, privacyStatus, categoryId, isValid, note}) {
    return request({
        url: '/api/v1/channel/youtube',
        method: 'put',
        data: {id, xiguaChannelId, channelName, channelHomeUrl, description, tags, privacyStatus, categoryId, isValid, note}
    });
}

//进行新的绑定 更新oauthToken youtube
export function createNewBinding({youtubeChannelId, clientSecretId}) {
    return request({
        url: '/api/v1/bind/youtube',
        method: 'POST',
        data: {id: youtubeChannelId, clientSecretId}
    });
}

//输入绑定的最终key
export function updateChannelToken({youtubeChannelId, clientSecretId, key}) {
    return request({
        url: '/api/v1/bind/key',
        method: 'POST',
        data: {id: youtubeChannelId, clientSecretId, key}
    });
}